import React from "react";
import "./App.css";
import BackgroundEffect from "./BackgroundEffect";

function App() {
  return (
    <div className="App">
      <BackgroundEffect />
    </div>
  );
}

export default App;
